import React from 'react';
import { useScrollTo } from '../../hooks/useScrollTo';

export const Offer: React.FC = () => {
  const scrollTo = useScrollTo();

  const handleContactClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const contactForm = document.getElementById('contact-form');
    if (!contactForm) return;

    const header = document.querySelector('.header');
    const headerHeight = header?.getBoundingClientRect().height || 0;
    scrollTo(contactForm, headerHeight, 500);
  };

  return (
    <section className="offer_area">
      <div className="inner">
        <div className="offer">
          <div className="offer_box">
            <p className="offer_tit">
              もっと詳しく知りたい方はこちら
            </p>
            <p className="offer_txt">
              ご相談やお見積もりなどお気軽にご相談ください
            </p>
          </div>
          <a href="#" className="btn" onClick={handleContactClick}>お問い合わせはこちら</a>
        </div>
      </div>
    </section>
  );
};