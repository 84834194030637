import React from 'react';

export const Support: React.FC = () => {
  return (
    <section className="support_area" id="support">
      <div className="inner">
        <p className="support_tit">
          導入<span>から</span>運用<span>まで</span>
        </p>
        <h2>
          <strong>万全<span>の</span>サポート体制</strong>
        </h2>
        <p className="support_eng">
          <picture>
            <source media="(min-width: 751px)" srcSet="/images/support_eng.png" />
            <img src="/images/support_eng_sp.png" alt="Support system" />
          </picture>
        </p>
        <ul className="support_list">
          <li className="support_list_item">
            <p className="support_list_img">
              <img src="/images/support_list_01_bg.png" alt="" />
            </p>
            <p className="support_list_tit">
              定期的な<br className="pc_item" />ソフトウェア<br />アップデートを提供
            </p>
            <p className="support_list_txt">
              <strong>AIシステムの性能改善や<strong>最新</strong>のセキュリティパッチ</strong>を含む定期的なソフトウェアアップデートを実施しています。<br />
              これにより、常に最適な<strong>パフォーマンスを保ちながら安全性も向上</strong>させることができます。
            </p>
          </li>
          <li className="support_list_item">
            <p className="support_list_img">
              <img src="/images/support_list_02_bg.png" alt="" />
            </p>
            <p className="support_list_tit">
              ハードウェアも<br />3年間の<br className="pc_item" />オンサイト保守付き
            </p>
            <p className="support_list_txt">
              システム導入後、3年間のオンサイト保守を提供します。<br />
              <strong>パーツ交換を無償</strong>で行うなど、手厚いサポートで安心してご利用いただけます(翌営業日)。
            </p>
          </li>
          <li className="support_list_item">
            <p className="support_list_img">
              <img src="/images/support_list_03_bg.png" alt="" />
            </p>
            <p className="support_list_tit">
              VPNを介した<br />安全な<br className="pc_item" />リモートサポート
            </p>
            <p className="support_list_txt">
              VPNを利用したリモートサポートにより、<strong>どこからでも迅速に問題解決が可能</strong>です。<br />
              専門知識がなくても安心してご利用いただける体制を整えています。
            </p>
          </li>
        </ul>
        <div className="support_read">
          <p className="support_read_tit">上記は<strong>全て3年間無償</strong><br className="sp_item" />となります</p>
          <p className="support_read_txt">追加のサポートについては<br className="sp_item" />お問い合わせください</p>
        </div>
      </div>
    </section>
  );
};