import React from 'react';

const Footer: React.FC = () => {

  return (
    <footer>
      <small>Copyright&copy; <a href="/">Cascade Inc.</a> All Rights Reserved.</small>
    </footer>
  );
};

export default Footer;