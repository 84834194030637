import React from 'react';
import './PrivacyPolicyDialog.css';

interface PrivacyPolicyDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PrivacyPolicyDialog: React.FC<PrivacyPolicyDialogProps> = ({
  isOpen,
  onClose
}) => {
  if (!isOpen) return null;

  return (
    <div className="dialog-overlay" onClick={onClose}>
      <div className="dialog-content" onClick={e => e.stopPropagation()}>
        <div className="dialog-header">
          <h2>プライバシーポリシー</h2>
        </div>
        <div className="dialog-body">
          <div className="privacy-content">
            <p>株式会社Cascade（以下，「当社」といいます。）は，ERUCORE製品およびサービス（以下,「本サービス」といいます。）における，お客様の情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。</p>

            <h2>第1条（定義）</h2>
            <ol>
              <li>「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報を指します。</li>
              <li>「企業情報」とは，本サービスを利用する法人のビジネス上の機密情報，営業情報，技術情報等を指します。</li>
              <li>「学習データ」とは，本サービスのAI機能の実行および改善のために使用される，お客様が提供するデータを指します。</li>
            </ol>

            <h2>第2条（情報の収集方法）</h2>
            <p>当社は，以下の方法により情報を収集します。</p>
            <ol>
              <li>本サービスご契約時の申込書等の書面</li>
              <li>お問い合わせフォームやメール等による連絡</li>
              <li>本サービスの利用状況の記録</li>
            </ol>

            <h2>第3条（情報の利用目的）</h2>
            <p>当社が収集した情報の利用目的は，以下のとおりです。</p>
            <ol>
              <li>本サービスの提供・運営およびカスタマイズのため</li>
              <li>本サービスの保守・メンテナンス，性能改善のため</li>
              <li>セキュリティ対策の実施・改善のため</li>
              <li>お客様からのお問い合わせ対応のため</li>
              <li>製品アップデートおよび重要なお知らせの連絡のため</li>
              <li>サービス利用料金の請求のため</li>
            </ol>

            <h2>第4条（オンプレミス環境でのデータ管理）</h2>
            <p>当社が収集した情報の利用目的は，以下のとおりです。</p>
            <ol>
              <li>ERUCOREはオンプレミス環境で動作し，お客様のデータは原則としてお客様の管理するサーバー内で処理されます。</li>
              <li>当社は，お客様の明示的な同意がない限り，お客様のデータにアクセスすることはありません。</li>
              <li>遠隔保守サポートを行う場合も，お客様の承認のもと，セキュアな接続を通じて実施します。</li>
            </ol>

            <h2>第5条（AIの学習データの取り扱い）</h2>
            <ol>
              <li>ERUCOREによって処理される企業情報や学習データは，お客様のローカル環境内で完結し，外部に送信されることはありません。</li>
            </ol>

            <h2>第6条（セキュリティ対策）</h2>
            <ol>
              <li>
                当社は，収集した情報の漏えい，滅失または毀損を防止するため，以下の安全管理措置を講じています。
                <ol>
                    <li>情報へのアクセス制限および管理</li>
                    <li>暗号化技術の採用</li>
                    <li>不正アクセス防止措置</li>
                    <li>セキュリティ監査の実施</li>
                </ol>
              </li>
            </ol>

            <h2>第7条（情報の第三者提供および販売パートナーとの共有）</h2>
            <ol>
              <li>
                <strong>第三者提供の制限</strong><br />
                当社は，以下の場合を除き，収集した情報を第三者に提供することはありません。
                <ol>
                  <li>お客様の同意がある場合</li>
                  <li>法令に基づく場合</li>
                  <li>人の生命，身体または財産の保護のために必要がある場合</li>
                  <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合</li>
                  <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合</li>
                </ol>
              </li>
              <li><strong>販売パートナーとの情報共有</strong><br />
                当社は，本サービスの販売・導入・保守サポートを効果的に実施するため，以下の条件のもと，当社認定販売パートナーと必要最小限の情報を共有することがあります。
                <ol>
                  <li><strong>共有する情報の範囲</strong>
                    <ul>
                      <li>お客様の企業名，部署名</li>
                      <li>ご担当者様の氏名，連絡先情報</li>
                      <li>製品導入状況，契約内容</li>
                      <li>サポート履歴</li>
                    </ul>
                  </li>
                  <li><strong>情報管理の徹底</strong>
                    <ul>
                      <li>販売パートナーとの間で秘密保持契約を締結</li>
                      <li>情報セキュリティに関する基準の遵守を義務付け</li>
                      <li>定期的な監査の実施</li>
                    </ul>
                  </li>
                  <li><strong>共有の目的</strong>
                    <ul>
                      <li>製品・サービスの提案</li>
                      <li>導入支援・技術サポート</li>
                      <li>保守メンテナンス</li>
                      <li>カスタマーサポート</li>
                    </ul>
                  </li>
                  <li>
                    <strong>お客様の権利</strong>
                    <ul>
                      <li>販売パートナーへの情報提供を停止する権利</li>
                      <li>共有された情報の開示・訂正を求める権利</li>
                      <li>具体的な共有先の開示を求める権利</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li><strong>販売パートナーの管理</strong><br />
                当社は，販売パートナーに対して以下の管理を実施します。
                <ol>
                  <li>パートナー認定制度の運用</li>
                  <li>定期的なセキュリティ研修の実施</li>
                  <li>情報取扱いに関する監査</li>
                  <li>インシデント発生時の報告体制の整備</li>
                </ol>
              </li>
            </ol>

            <h2>第8条（開示・訂正・利用停止）</h2>
            <ol>
              <li>当社は，お客様から個人情報の開示，訂正，利用停止等の請求があった場合，本人確認を行った上で，適切に対応します。</li>
              <li>開示請求には，合理的な範囲での手数料を申し受ける場合があります。</li>
            </ol>

            <h2>第9条（プライバシーポリシーの変更）</h2>
            <ol>
              <li>本ポリシーの内容は，法令変更や技術的要請等により，予告なく変更することがあります。</li>
              <li>重要な変更については，本サービスのウェブサイト上での告知または電子メールにてお客様に通知します。</li>
            </ol>

            <h2>第10条（お問い合わせ窓口）</h2>
            <p>本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。</p>
            <p className="contact-info">
              株式会社カスケード<br />
              住所：大阪府大阪市淀川区西中島5丁目6-13新大阪御幸ビル4階<br />
              部署：ERUCOREサポート担当<br />
              Email：contact@cascade.co.jp
            </p>

            <p className="policy-date">制定日：2024年11月21日</p>
          </div>
        </div>
      </div>
    </div>
  );
};