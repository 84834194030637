import React from 'react';
import { Offer } from './Offer';

export const Merit: React.FC = () => {
  return (
    <section className="merit_area" id="merit">
      <div className="inner">
        <h2>
          ERUCORE導入<span>の</span><br className="sp_item" />メリット
        </h2>
      </div>
      <ul className="merit_list">
        <li className="merit_list_item">
          <div className="inner">
            <div className="merit_list_box">
              <p className="merit_list_num"><img src="/images/merit_list_01_num.png" alt="merit 01" /></p>
              <p className="tit">
                業務効率化
              </p>
              <p className="txt">
                <strong className="pc_item">大量のドキュメントから必要な情報をAIが瞬時に抽出。</strong>
                <strong className="sp_item">大量のドキュメントから必要な情報をAIが</strong><br className="sp_item" />
                <strong className="sp_item">瞬時に抽出。</strong>
                <br />
                従来20分かかる情報探索が25秒で完了し、<br />
                時間短縮が最大98％に。<br />
                業務負担が軽減され、社員の生産性が<br className="sp_item" />向上します。
              </p>
            </div>
            <p className="merit_list_img">
              <picture>
                <source media="(min-width: 751px)" srcSet="/images/merit_list_01_item.png" />
                <img src="/images/merit_list_01_item_sp.png" alt="情報探索時間 98%時間短縮" />
              </picture>
            </p>
          </div>
        </li>
        <li className="merit_list_item">
          <div className="inner">
            <div className="merit_list_box">
              <p className="merit_list_num"><img src="/images/merit_list_02_num.png" alt="merit 02" /></p>
              <p className="tit">
                イノベーション
              </p>
              <p className="txt">
                AIが複数ドキュメントを横断的に分析する<br className="sp_item" />ことで<br className="pc_item" />
                <strong>隠れた関連性を発見しやすくなり、</strong><br />
                <strong>社内イノベーションが促進</strong>されます。<br />
                新たなアイデア創出や意思決定の迅速化に<br className="sp_item" />貢献します。
              </p>
            </div>
          </div>
        </li>
        <li className="merit_list_item">
          <div className="inner">
            <div className="merit_list_box">
              <p className="merit_list_num"><img src="/images/merit_list_03_num.png" alt="merit 03" /></p>
              <p className="tit">
                拡張性
              </p>
              <p className="txt">
                ERUCOREは<strong className="pc_item">APIを介して社内システムと連携可能。</strong>
                <strong className="sp_item">APIを介して社内システムと</strong><br className="sp_item" />
                <strong className="sp_item">連携可能。</strong><br />
                既存のシステム環境にスムーズに統合でき、<br />
                利用者の要件に応じて拡張頂けます。
              </p>
            </div>
            <p className="merit_list_img">
              <picture>
                <source media="(min-width: 751px)" srcSet="/images/merit_list_03_item.png" />
                <img src="/images/merit_list_03_item_sp.png" alt="社内システムと連携可能" />
              </picture>
            </p>
          </div>
        </li>
      </ul>
      <Offer />
    </section>
  );
};