import React from 'react';

export const Features: React.FC = () => {
  return (
    <section className="features_area" id="features">
      <div className="inner inner_01">
        <h2>
          <picture>
            <source media="(min-width: 751px)" srcSet="/images/features_tit.png" />
            <img src="/images/features_tit_sp.png" alt="Features ERUCOREの特徴" />
          </picture>
        </h2>
      </div>
      <ul className="features_list">
        <li className="features_list_item">
          <div className="inner">
            <p className="tit">
              <strong>専門知識がなくても</strong><br />
              すぐにAIを<strong>業務に活用</strong>できる
            </p>
            <p className="txt">
              ERUCOREは専門知識が不要で、<br className="sp_item" />導入後すぐにAIを活用できます。<br />
              直感的な操作が可能なため、担当者に<br className="sp_item" />負担なく業務に取り入れ<br className="pc_item" />られます。
            </p>
          </div>
        </li>
        <li className="features_list_item">
          <div className="inner">
            <p className="tit">
              社内に散在する情報を<br />
              <strong>簡単に一元管理</strong>
            </p>
            <p className="txt">
              社内に点在する文書やナレッジを<br className="sp_item" />一元化し、AIによる<br className="pc_item" />効率的な検索・分析が<br className="sp_item" />可能です。<br />
              部門間の情報共有をスムーズにし、<br className="sp_item" />業務効率を大幅に改善します。
            </p>
          </div>
        </li>
        <li className="features_list_item">
          <div className="inner">
            <p className="tit">
              <strong>オフライン運用</strong>と<br className="sp_item" /><strong>強固なセキュリティ</strong>で<br />
              機密データの管理も安心
            </p>
            <p className="txt">
              ERUCOREは高性能なワークステーションに<br className="sp_item" />搭載され、オフラインで安全に運用可能。<br />
              セキュリティ性が高く機密情報の<br className="sp_item" />漏洩リスクを低減し、<br className="pc_item" />
              重要データを<br className="sp_item" />安心して管理できます。
            </p>
          </div>
        </li>
      </ul>
    </section>
  );
};