import React from 'react';
import { useScrollTo } from '../../hooks/useScrollTo';

export const About: React.FC = () => {
  const scrollTo = useScrollTo();

  const handleContactClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    const contactForm = document.getElementById('contact-form');
    if (!contactForm) return;

    const header = document.querySelector('.header');
    const headerHeight = header?.getBoundingClientRect().height || 0;
    scrollTo(contactForm, headerHeight, 500);
  };

  return (
    <section className="about_area" id="about">
      <div className="inner">
        <h2>
          <picture>
            <source media="(min-width: 751px)" srcSet="/images/about_tit.png" />
            <img src="/images/about_tit_sp.png" alt="ERUCOREとは？" />
          </picture>
        </h2>
        <p className="about_txt">
          ERUCOREは高性能ワークステーションに<br className="sp_item" />搭載された<strong>オフライン運用可能な<br className="sp_item" />AI文章管理システム</strong>です。<br />
          DX推進の基盤を構築し、セキュリティと<br className="sp_item" />業務効率化を同時に実現します。
        </p>
        <ul className="about_list">
          <li className="about_list_item">
            <p className="txt">
              <strong>AIやDXの導入を検討</strong>して<br />
              いるが具体的な行動に<br />
              移せていない
            </p>
          </li>
          <li className="about_list_item">
            <p className="txt">
              <strong>部門間での知識共有</strong>に<br />
              悩みを抱えている
            </p>
          </li>
          <li className="about_list_item">
            <p className="txt">
              既存のAIサービスに対し<br />
              <strong>セキュリティ上の<br />
                不安</strong>がある
            </p>
          </li>
        </ul>
        <p className="about_read">
          こうした<strong>ご要望</strong><span>に</span><br className="sp_item" />お応えすること<span>が</span>できます
        </p>
        <div className="about_detail">
          <div className="about_detail_wrap">
            <div className="about_detail_box">
              <p className="about_detail_tit">
                もっと詳しく知りたい方はこちら
              </p>
              <p className="about_detail_txt">
                ご相談やお見積もりなどお気軽にご相談ください
              </p>
            </div>
            <a href="#" className="btn" onClick={handleContactClick}>お問い合わせはこちら</a>
          </div>
        </div>
      </div>
    </section>
  );
};