// src/hooks/useScrollTo.ts
export const useScrollTo = () => {
  const scrollTo = (
    element: HTMLElement | null,
    offset: number = 0,
    duration: number = 500
  ) => {
    if (!element) return;

    const start = window.pageYOffset;
    const target = element.getBoundingClientRect().top + window.pageYOffset - offset;
    const diff = target - start;
    let startTime: number | null = null;

    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      const easeInOutQuad = (t: number) => {
        return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
      };

      window.scrollTo(0, start + diff * easeInOutQuad(progress));

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  };

  return scrollTo;
};