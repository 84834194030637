import React from 'react';

export const MainVisual: React.FC = () => {
  return (
    <section className="fv_area">
      <div className="inner">
        <h1>
          <span>
            <picture>
              <source media="(min-width: 751px)" srcSet="/images/fv_tit.png" />
              <img src="/images/fv_tit_sp.png" alt="AI導入はもう怖くない!社内の知識を循環させイノベーションを加速する" />
            </picture>
          </span>
        </h1>
        <p>
          <picture>
            <source media="(min-width: 751px)" srcSet="/images/fv_name.png" />
            <img src="/images/fv_name_sp.png" alt="ERUCORE" />
          </picture>
        </p>
        <p>
          <picture>
            <source media="(min-width: 751px)" srcSet="/images/fv_list.png" />
            <img src="/images/fv_list_sp.png" alt="大量の文章をAIが瞬時に検索・分析 オフライン運用による高セキュリティ 直感的な操作で誰でも使える" />
          </picture>
        </p>
      </div>
    </section>
  );
};