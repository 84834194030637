import React, { useState } from 'react';
import { PrivacyPolicyDialog } from '../shared/PrivacyPolicyDialog';

interface FormData {
  company: string;
  lastName: string;
  firstName: string;
  email: string;
  position: string;
  phone: string;
  message: string;
  inquiryStatus: string;
}

interface FormErrors {
  company?: string;
  lastName?: string;
  firstName?: string;
  email?: string;
  position?: string;
  phone?: string;
  message?: string;
  inquiryStatus?: string;
}

const positions = [
  "経営者・役員",
  "部長",
  "課長",
  "係長・主任",
  "一般社員",
  "契約・嘱託・派遣等",
  "個人",
  "その他"
] as const;

const inquiryStatuses = [
  "導入を考えている",
  "まずは相談したい",
  "費用が知りたい",
  "その他"
] as const;

export const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    company: '',
    lastName: '',
    firstName: '',
    email: '',
    position: '',
    phone: '',
    message: '',
    inquiryStatus: ''
  });

  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState('');
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);

  const validateEmail = (email: string) => {
    const regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return regex.test(email);
  };

  const validatePhone = (phone: string) => {
    const regex = /^[0-9-]+$/;
    return regex.test(phone);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newErrors: FormErrors = {};

    // すべての必須フィールドのバリデーション
    if (!formData.company.trim()) {
      newErrors.company = '会社名を入力してください';
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = '姓を入力してください';
    }
    if (!formData.firstName.trim()) {
      newErrors.firstName = '名を入力してください';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'メールアドレスを入力してください';
    } else if (!validateEmail(formData.email)) {
      newErrors.email = '正しいメールアドレスの形式で入力してください';
    }
    if (!formData.position) {
      newErrors.position = '役職を選択してください';
    }
    if (!formData.phone.trim()) {
      newErrors.phone = '電話番号を入力してください';
    } else if (!validatePhone(formData.phone)) {
      newErrors.phone = '電話番号は数字とハイフンのみ使用できます';
    }
    if (!formData.inquiryStatus) {
      newErrors.inquiryStatus = 'ご検討状況を選択してください';
    }

    // エラーがある場合は送信しない
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);
    setMessage('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }

      setMessage('お問い合わせを受け付けました。');
      setFormData({
        company: '',
        lastName: '',
        firstName: '',
        email: '',
        position: '',
        phone: '',
        message: '',
        inquiryStatus: ''
      });
      setErrors({});
    } catch (error) {
      setMessage(error instanceof Error ? error.message : 'お問い合わせの送信に失敗しました。');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="contact-form-area" id="contact-form">
      <div className="inner">
        <h2>
          <picture>
            <source media="(min-width: 751px)" srcSet="/images/contact_form_tit_sp.png" />
            <img src="/images/contact_form_tit_sp.png" alt="お問い合わせ" />
          </picture>
        </h2>
        <div className="contact-form-container">
          <form onSubmit={handleSubmit} className="contact-form" noValidate>
            <div className="form-group">
              <label htmlFor="company">
                会社名
                <span className="required">必須</span>
              </label>
              <input
                type="text"
                id="company"
                value={formData.company}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, company: e.target.value }));
                  if (errors.company) {
                    setErrors(prev => ({ ...prev, company: undefined }));
                  }
                }}
              />
              {errors.company && <div className="error-message">{errors.company}</div>}
            </div>

            <div className="name-group">
              <div className="form-group">
                <label htmlFor="lastName">
                  姓
                  <span className="required">必須</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  value={formData.lastName}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, lastName: e.target.value }));
                    if (errors.lastName) {
                      setErrors(prev => ({ ...prev, lastName: undefined }));
                    }
                  }}
                />
                {errors.lastName && <div className="error-message">{errors.lastName}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firstName">
                  名
                  <span className="required">必須</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  value={formData.firstName}
                  onChange={(e) => {
                    setFormData(prev => ({ ...prev, firstName: e.target.value }));
                    if (errors.firstName) {
                      setErrors(prev => ({ ...prev, firstName: undefined }));
                    }
                  }}
                />
                {errors.firstName && <div className="error-message">{errors.firstName}</div>}
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="email">
                メールアドレス
                <span className="required">必須</span>
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, email: e.target.value }));
                  if (errors.email) {
                    setErrors(prev => ({ ...prev, email: undefined }));
                  }
                }}
              />
              {errors.email && <div className="error-message">{errors.email}</div>}
            </div>

            <div className="form-group">
              <label htmlFor="position">
                役職
                <span className="required">必須</span>
              </label>
              <select
                id="position"
                value={formData.position}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, position: e.target.value }));
                  if (errors.position) {
                    setErrors(prev => ({ ...prev, position: undefined }));
                  }
                }}
              >
                <option value="">選択してください</option>
                {positions.map((pos) => (
                  <option key={pos} value={pos}>{pos}</option>
                ))}
              </select>
              {errors.position && <div className="error-message">{errors.position}</div>}
            </div>

            <div className="form-group">
              <label htmlFor="inquiryStatus">
                ご検討状況
                <span className="required">必須</span>
              </label>
              <select
                id="inquiryStatus"
                value={formData.inquiryStatus}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, inquiryStatus: e.target.value }));
                  if (errors.inquiryStatus) {
                    setErrors(prev => ({ ...prev, inquiryStatus: undefined }));
                  }
                }}
              >
                <option value="">選択してください</option>
                {inquiryStatuses.map((status) => (
                  <option key={status} value={status}>{status}</option>
                ))}
              </select>
              {errors.inquiryStatus && <div className="error-message">{errors.inquiryStatus}</div>}
            </div>

            <div className="form-group">
              <label htmlFor="phone">
                日中繋がりやすい電話番号
                <span className="required">必須</span>
              </label>
              <input
                type="tel"
                id="phone"
                value={formData.phone}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, phone: e.target.value }));
                  if (errors.phone) {
                    setErrors(prev => ({ ...prev, phone: undefined }));
                  }
                }}
              />
              {errors.phone && <div className="error-message">{errors.phone}</div>}
            </div>

            <div className="form-group">
              <label htmlFor="message">
                その他、ご質問ご要望があればご記入ください
                <span className="optional">任意</span>
              </label>
              <textarea
                id="message"
                value={formData.message}
                onChange={(e) => {
                  setFormData(prev => ({ ...prev, message: e.target.value }));
                  if (errors.message) {
                    setErrors(prev => ({ ...prev, message: undefined }));
                  }
                }}
              />
              {errors.message && <div className="error-message">{errors.message}</div>}
            </div>

            <div className="privacy-policy">
              <p>
                個人情報は、
                <button
                  type="button"
                  className="privacy-policy-link"
                  onClick={() => setIsPrivacyPolicyOpen(true)}
                >
                  プライバシーポリシー
                </button>
                に基づき適切に管理いたします。
              </p>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
            >
              {isSubmitting ? '送信中...' : 'プライバシーポリシーに同意のうえ送信する'}
            </button>

            {message && (
              <div className={`submit-message ${message.includes('受け付けました') ? 'success' : 'error'}`}>
                {message}
              </div>
            )}
            <PrivacyPolicyDialog
              isOpen={isPrivacyPolicyOpen}
              onClose={() => setIsPrivacyPolicyOpen(false)}
            />
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;