import React from 'react';

export const Flow: React.FC = () => {
  return (
    <section className="flow_area">
      <div className="inner">
        <h2>
          <picture>
            <source media="(min-width: 751px)" srcSet="/images/flow_tit.png" />
            <img src="/images/flow_tit_sp.png" alt="導入までの流れ" />
          </picture>
        </h2>
        <ul className="flow_list">
          <li className="flow_list_item">
            <p className="flow_list_num">
              <img src="/images/flow_list_01_num.png" alt="01" />
            </p>
            <p className="tit">
              お問い合わせ
            </p>
            <p className="txt">
              お問い合わせフォームよりお気軽にお問い合わせください。
            </p>
          </li>
          <li className="flow_list_item">
            <p className="flow_list_num">
              <img src="/images/flow_list_02_num.png" alt="02" />
            </p>
            <p className="tit">
              導入相談
            </p>
            <p className="txt">
              担当者からご連絡致しますので現状やご要望についてお聞かせください。
            </p>
          </li>
          <li className="flow_list_item">
            <p className="flow_list_num">
              <img src="/images/flow_list_03_num.png" alt="03" />
            </p>
            <p className="tit">
              お申し込み<br />日程調整
            </p>
            <p className="txt">
              お見積もりをご掲示させて頂きます。ご契約頂けましたら導入のお手続きとなります。
            </p>
          </li>
          <li className="flow_list_item">
            <p className="flow_list_num">
              <img src="/images/flow_list_04_num.png" alt="04" />
            </p>
            <p className="tit">
              専用ワーク<br />ステーションをお届け
            </p>
            <p className="txt">
              お届けしたその日から利用可能です。貴社のサポートを迅速に開始いたします。
            </p>
          </li>
        </ul>
      </div>
    </section>
  );
};