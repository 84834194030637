import React, { useState, useEffect } from 'react';
import { useScrollTo } from '../../hooks/useScrollTo';

export const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 750);
  const scrollTo = useScrollTo();

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 750;
      setIsMobile(mobile);
      if (!mobile) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.classList.toggle('no-scroll');
  };

  const handleNavClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const href = e.currentTarget.getAttribute('href');
    if (!href) return;
    
    const target = document.querySelector(href);
    if (!target) return;

    const header = document.querySelector('.header');
    const headerHeight = header?.getBoundingClientRect().height || 0;
    const offset = isMobile ? 0 : headerHeight;

    if (isMobile) {
      handleMenuToggle();
      setTimeout(() => {
        scrollTo(target as HTMLElement, offset, 500);
      }, 100);
    } else {
      scrollTo(target as HTMLElement, offset, 500);
    }
  };

  const handleContactClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
  
    const contactForm = document.getElementById('contact-form');
    if (!contactForm) return;
  
    if (isMobile) {
      handleMenuToggle();
      setTimeout(() => {
        scrollTo(contactForm, 0, 500);
      }, 100);
    } else {
      const header = document.querySelector('.header');
      const headerHeight = header?.getBoundingClientRect().height || 0;
      scrollTo(contactForm, headerHeight, 500);
    }
  };

  return (
    <>
      <header className={`header ${isMenuOpen ? 'active' : ''}`}>
        <div className="inner">
          <div className="header_wrap">
            <p className="header_logo">
              <img src="/images/header_logo.png" alt="ERUCORE" />
            </p>
            <div className="header_box">
              <ul className="header_list">
                <li className="header_list_item">
                  <a href="#about" onClick={handleNavClick}>
                    <p className="eng">About</p>
                    <p className="txt">ERUCOREとは</p>
                  </a>
                </li>
                <li className="header_list_item">
                  <a href="#features" onClick={handleNavClick}>
                    <p className="eng">Features</p>
                    <p className="txt">ERUCOREの特徴</p>
                  </a>
                </li>
                <li className="header_list_item">
                  <a href="#merit" onClick={handleNavClick}>
                    <p className="eng">Merit</p>
                    <p className="txt">導入メリット</p>
                  </a>
                </li>
                <li className="header_list_item">
                  <a href="#support" onClick={handleNavClick}>
                    <p className="eng">Support</p>
                    <p className="txt">サポート</p>
                  </a>
                </li>
              </ul>
              <a href="#" className="btn" onClick={handleContactClick}>お問い合わせはこちら</a>
            </div>
          </div>
        </div>
      </header>
      {isMobile && (
        <div className={`ham_btn sp_item ${isMenuOpen ? 'active' : ''}`} onClick={handleMenuToggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      )}
    </>
  );
};