import React from 'react';
import { Header } from './components/Layout/Header';
import { MainVisual } from './components/Home/MainVisual';
import { About } from './components/Home/About';
import { Features } from './components/Home/Features';
import { Merit } from './components/Home/Merit';
import { Support } from './components/Home/Support';
import { Flow } from './components/Home/Flow';
import { ContactForm } from './components/Home/ContactForm';
import { Offer } from './components/Home/Offer';
import Footer from './components/Layout/Footer';
import './styles/sanitize.css';
import './styles/style.css';

const App: React.FC = () => {
  return (
    <div className="lp_wrap">
      <Header />
      <main>
        <MainVisual />
        <About />
        <Features />
        <Merit />
        <Support />
        <Flow />
        <ContactForm />
        <Offer />
      </main>
      <Footer />
    </div>
  );
};

export default App;